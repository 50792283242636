/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: 'Roboto', sans-serif;
}

.error-validation {
  color: red;
  font-style: italic;
}

.dp-main, .dp-day-calendar-container {
  width: 100% !important;
}

.dp-main {
  div {
    right: 0 !important;
  }
}

.dp-weekdays {
  background: #3880ff;

  display: flex;
  .dp-calendar-weekday {
    flex: 1;
    color: #FFF !important;
    font-size: 16px !important;
  }
}

.dp-calendar-week {
  display: flex;
  button {
    flex: 1;
  }
}

.dp-input-container {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  .dp-picker-input {
    width: 100% !important;
    margin: 0 auto;

    font-weight: bold;
    padding: 10px;
    text-align: center;
    font-size: 18px !important;
  }
}

.booking-modal > .modal-wrapper {
  height: 820px;
}